/*the container must be positioned relative:*/
.FlatDropDown {
  position: relative;
  font-family: Arial;
  width: 200px;
  display: inline-block;
}

.FlatDropDown select {
  display: none; /*hide original SELECT element:*/
}

.FlatDropDown .select-selected {
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(119, 119, 119);
  border-radius: 3px;
}
.FlatDropDown .select-selected > li{
  list-style-type: none;
  overflow: hidden;
  margin-right: 10px;
}
.select-items > div{
overflow: hidden;
list-style-type: none;

}

/*style the arrow inside the select element:*/
.FlatDropDown .select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color:black transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.FlatDropDown .select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.FlatDropDown .select-items div,.select-selected {
  color: black;/*#ffffff;*/
  padding: 7px 16px;
  border: 0px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.FlatDropDown .select-items {
  position: absolute;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(119, 119, 119);
  border-radius: 3px;
  background-color: white; /*rgb(63, 62, 62);*/
  /* top: 100%; */
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.FlatDropDown .select-hide {
  display: none;
}

.FlatDropDown .select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
