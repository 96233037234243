.btnLogin{
    height: 50px;
    font-size: 22px;
}
.modal{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
}
.modal .modal-content{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    text-align: right;
    background-color: rgba(255, 255, 255 ,.9);
    border-radius: 24px;
    padding: 1% ;
    /* width: 22%; */
    max-width: 456.7px;
    height: 300px;
    /* max-height: 300px; */
    box-shadow: inset 0 0 0 2px #002a61;
}
.modal .modal-content .close{
    color: #000;
    cursor: pointer;
}
.modal .modal-content p{
    text-align: left;
    font-size: 0.9rem;
    color: #000;
    margin: 0 3% 4%;
}
.modal .modal-content p .SizeUp{
    font-size: 1.1rem;
    font-weight: bold;
}
.modal .modal-content p .BoldUp{
    font-weight: bold;
}
.popupBotm{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.popupBotm p img{
    width: 7%;
    margin-right: 1%;
}
.popupCheck label {
    color:#000;
}
.PopupDown{
    text-decoration: none;
    color: #fff;
    background-color: #01BCD6;
    padding: 2% 3%;
    border-radius: 10px;
    font-weight: bold;
    margin-right: 3%;
    white-space: nowrap;
    font-size: 0.9rem;
    text-align: center;
}
.PopupDown:hover{
    background-color: #002a61;
}
.PopupDown img{
    width: 8%;
}
.popupCheck{
    margin: 3% 4% 0 0;
}
.popupCheck label, .popupCheck input{
    display: inline-block;
    font-size: 0.9rem;
}

.popupCheck input{
    width: 5%;
}
input[type="password"]{
    font-size: 22px;
}
input[type="text"]{
    font-size: 22px;
}
.login-btn {
    padding: 12px;
    background-color: #00ADB5;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
.login-btn:hover {
    background-color: #112D4E;
  }
@media screen and (max-width: 1824px) {
    .modal .modal-content{
        width: 25%;
    }
}
@media screen and (max-width: 1605px) {
    .modal .modal-content{
        width: 28%;
    }
}
@media screen and (max-width: 1433px) {
    .modal .modal-content{
        width: 30%;
    }
}
@media screen and (max-width: 1337px) {
    .modal .modal-content{
        width: 33%;
    }
}
@media screen and (max-width: 1216px) {
    .modal .modal-content{
        width: 36%;
    }
}
@media screen and (max-width: 1114px) {
    .modal .modal-content{
        width: 40%;
    }
}
@media screen and (max-width: 1003px) {
    .modal .modal-content{
        width: 45%;
        padding-top: 3%;
    }
}
@media screen and (max-width: 886px) {
    .modal .modal-content{
        width: 50%;
    }
}
@media screen and (max-width: 803px) {
    .modal .modal-content{
        width: 55%;
    }
}
@media screen and (max-width: 729px) {
    .modal .modal-content{
        width: 60%;
    }
}
@media screen and (max-width: 668px) {
    .modal .modal-content{
        width: 65%;
    }
}
@media screen and (max-width: 617px) {
    .modal .modal-content{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 310px;
        padding-top: 4%;
    }
}
@media screen and (max-width: 443px) {
    .modal .modal-content{
        height: 400px;
        padding-top: 5%;
    }
    .nonBr{
        display: none;
    }
    .popupBotm{
        display: flex;
        /* justify-content: space-between;
        align-items: center; */
        flex-direction: column;
    }
    .popupBotm p img{
        width: 5%;
    }
    .PopupDown{
        padding: 2% 3%;
        margin-right: 0%;
        width: 50%;
        margin-top: 5%;
    }
}
@media screen and (max-width: 426px) {
    .modal .modal-content{
        height: 420px;
    }
    
    .nonBr2{
        display: none;
    }
    .popupBotm{
        display: flex;
        /* justify-content: space-between;
        align-items: center; */
        flex-direction: column;
    }
    .popupBotm p img{
        width: 5%;
    }
    .PopupDown{
        padding: 2% 3%;
        margin-right: 0%;
        width: 50%;
        margin-top: 5%;
    }
}
@media screen and (max-width: 295px) {
    .modal .modal-content{
        height: 460px;
        padding-top: 8%;
    }
    .popupBotm p img{
        width: 6%;
    }
    .PopupDown{
        padding: 3%;
        margin-right: 0%;
        width: 55%;
        margin-top: 5%;
    }
}