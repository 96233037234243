.Register .container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    width: 100%;
  }
  
  .Register .form-box {
    position: relative;
    width: 480px;
    min-height: 700px;
    padding: 40px 50px;
    border-radius: 20px;
    backdrop-filter: blur(15px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
  }
  
  
  .Register .form-box h2 {
    font-size:  28px;
    text-align: center;
    margin-bottom: 35px;
    color: #333;
  }
  
  .Register .input-register {
    width: 100%;        /* 입력 필드 전체 너비 사용 */
    max-width: 400px;   /* 최대 너비 제한 */
    margin: 0 auto;     /* 가운데 정렬 */
    margin-bottom: 20px;
  }
  
  .Register .input-register input {
    width: calc(100% - 32px);
    height: 40px;
    border: 1px solid #e1e1e1;
    border-radius: 12px;
    outline: none;
    padding: 0 15px;
    font-size: 15px;
    color: #333;
    transition: all 0.2s ease;
  }

  .Register .input-register-email input {
    width: 100%;
    height: 40px;
    border: 1px solid #e1e1e1;
    border-radius: 12px;
    outline: none;
    padding: 0 15px;
    font-size: 15px;
    color: #333;
    transition: all 0.2s ease;
    margin-bottom : 20px
  }

  .Register .input-register input::placeholder {
    color: #333;
  }
  
  .Register i {
    position: absolute;
    right: 0;
    top: 13px;
    color: #fff;
  }
  
  .Register .registerBtn {
    width: 100%;
    height: 50px;
    background: #00ADB5;
    border-radius: 5px;
    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;
    margin: 20px 0px;
  }

  .Register .registerBtn:hover {
    background: #112D4E;
}
  
  .Register .group {
    display: flex;
    justify-content: space-between;
  }
  
  .Register .group span a {
    position: relative;
    top: 10px;
    color: #fff;
    font-weight: 500;
  }
  
  .Register .group a:focus {
    text-decoration: underline;
  }
  
  .Register #result {
    text-align: center;
    color: #FF4081;
    font-size: 14px;
    margin: 10px 0;
  }
  
  .Register .popup {
    visibility: hidden;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%) scale(0);
    width: 400px;
    height: 330px;
    background: #fff;
    border-radius: 6px;
    padding: 0 30px 30px;
    color: #000;
    text-align: center;
    transition: transform 0.4s, top 0.4s;
  }
  
  .Register .popup ion-icon {
    font-size: 50px;
    color: #00ff00;
    padding-top: 45px;
  }
  
  .Register .popup button {
    width: 100%;
    background: #00ff00;
    border-radius: 4px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    margin-top: 50px;
    border: none;
    outline: none;
    font-size: 18px;
    color: #777;
    font-weight: 600;
    cursor: pointer;
  }
  
  .Register .popup a {
    color: #fff;
    font-weight: 600;
    letter-spacing: 2px;
  }
  
  .Register .open-slide {
    visibility: visible;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
  }

  .Register .login-box {
    flex-direction: column;
    position: relative;
    width: 420px;
    height: 500px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Register   .login-box h2 {
    font-size: 32px;
    color: #fff;
    text-align: center;
  }
  

  .Register .input-login {
    position: relative;
    width: 310px;
    border-bottom: 2px solid #fff;
    margin: 30px 0;
  }
  
  .Register .input-login input {
    width: 100%;
    height: 45px;
    background: transparent;
    border: none;
    outline: none;
    padding: 0 20px 0 5px;
    font-size: 16px;
    color: #fff;
  }

  .input-login input::placeholder {
    color: #fff;
  }
  .loginIdInput {
    border: none;
  }

  .Register .loginBtn {
    width: 20rem;
    height: 50px;
    background: dodgerblue;
    border-radius: 5px;
    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;
    font-size: 17px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 2px;
    cursor: pointer;
    margin: 20px 0px;
  }

  .Register .forgetSec {
    display: flex;
  }

  .Register .forgetSec a {
    font-size: 12px;
    margin: 0px 30px;

  }

  .Register .input-register-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;  
    gap: 12px; /* 필요에 따라 간격 조절 */
    width: 20rem;
  }
  
  .Register .input-register-radio {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }
  
  .Register .input-register select {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border: 1px solid #e1e1e1;
    border-radius: 12px;
    font-size: 15px;
    background-color: white;
    position: relative;
    cursor: pointer;
    color: #333;  /* 텍스트 색상 추가 */
    /* 기본 select 스타일 제거 */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  

  .input-register {
    position: relative;
}

  .Register .input-register select:focus {
    outline: none; /* 포커스 시 아웃라인 제거 */
    border-color: #2969c9; /* 포커스 시 테두리 색상 변경 */
  }
  

  
  .Register .input-register::after {
    content: none;  /* 기본적으로 화살표 제거 */
  }

  .Register .input-register:has(select)::after {
    content: '';  /* select가 있는 경우에만 화살표 표시 */
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #999;
    pointer-events: none;
}

  .Register   .registerLink {
    color: #fff;
    text-decoration: none;
  }

/* input-register-group 내부의 요소들 너비 조절 */
.input-register-group {
  display: flex;
  justify-content: space-between;
  width: 100%;    /* 전체 너비 사용 */
    max-width: 400px;  /* 최대 너비 제한 */
    gap: 12px;     /* 요소 간 간격 */
  margin: 0 auto 10px;
}

.Register .input-register-group input {
  height: 40px;
  padding: 0 10px;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  font-size: 15px;
  color: #333;
  outline: none;
  width: 100%;   /* 부모 요소의 너비에 맞춤 */
  height: 40px;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  font-size: 15px;
  text-align: center;
}

.input-register-group .input-register {
  flex: 1;  /* 동일한 비율로 공간 차지 */
  min-width: 0;  /* flex item 최소 너비 설정 */
}

.Register .input-register-group .input-register:nth-child(1) {
  width: 100px;  /* 생년(YYYY) */
}

.Register .input-register-group .input-register:nth-child(2),
.Register .input-register-group .input-register:nth-child(3) {
    width: 60px;   /* 생월(MM), 생일(DD) */
}

/* 생년월일 input과 select의 너비 통일 */
.Register .input-register-group .input-register input,
.Register .input-register-group .input-register select {
  width: 80px;
  height: 40px;
}