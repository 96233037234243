.CartBox{
    padding: 3rem;
    background: #fff;
    border-width: 10;
    border-radius: 10px;
}

.css-16{
    display:flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    border-width: 1px 0px 0px;
    border-color: rgba(0,0,0,0.05);
    border-style:solid;
    width: 100%;
    margin-top:10px;
    margin-bottom: 10px;
}